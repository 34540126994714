/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {Modal, Button, Grid, Card, CardHeader} from '@material-ui/core'
import {graphql} from 'gatsby'
import Layout from "../components/layout"
import "./becometrainer.css";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import MultiImageSlider from "../components/MultiImageSlider";
import CardContent from "@material-ui/core/CardContent";
import EventsOverview from "../components/EventsOverview";
import SEO from "../components/SEO";
import Map from '../components/MyMapComponent'
import Contact from "../components/Contact";
import Slider from "react-slick";
import Banner from "../components/Banner";
import Empfehlungen from "../components/empfehlungen";

const sliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    fade: true
};

export default class Becometrainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: [{
                title: this.props.data.wordpressPage.acf.maps_marker_title,
                key: this.props.data.wordpressPage.acf.maps_marker_title,
                description: this.props.data.wordpressPage.acf.maps_marker_description,
                markerLink: this.props.data.wordpressPage.acf.maps_marker_link,
                lat: parseFloat(this.props.data.wordpressPage.acf.maps && this.props.data.wordpressPage.acf.maps.lat || 0),
                lng: parseFloat(this.props.data.wordpressPage.acf.maps && this.props.data.wordpressPage.acf.maps.lng || 0)
            }]
        };
    }

    render() {

        return <Layout id="becomeTrainer">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />
            <Slider {...sliderSettings}>
                {
                    this.props.data.wordpressPage.acf.slider_page.map((slide) => {
                        return (
                            slide.image && <Banner
                                customStyles={{marginTop: 0}}
                                title={slide.title}
                                description={slide.description}
                                image={slide.image}
                            />
                        );
                    })
                }
            </Slider>

            <Grid container type="flex" justify="center" className='content-wrapper'>
                <Grid item xl={6} lg={8} md={12} xs={12} className='content-centered'>
                    <h2 className='title-big'>{this.props.data.wordpressPage.acf.become_trainer.title}</h2>
                    <div>{this.props.data.wordpressPage.acf.become_trainer.description}</div>
                </Grid>
            </Grid>

            <div className='dark-hero-content'>
                <h2 className='title-big'>{this.props.data.wordpressPage.acf.become_trainer.courses_title}</h2>
            </div>

            <Grid container  className="becometrainer-content-row" type="flex" justify="start">
                <EventsOverview categories={[this.props.data.wordpressPage.acf.become_trainer.event_filter_categories]} types={['Ausbildung']} />
            </Grid>

            <div className='video-wrapper'>
                <div className='apnoe-video-container'>
                    <div className="video-wrapper" style={{margin: '0 auto'}}>
                        <video controls className="video" poster={this.props.data.wordpressPage.acf.video.thumbnail && this.props.data.wordpressPage.acf.video.thumbnail.src}>
                            <source src={this.props.data.wordpressPage.acf.video.file.source_url} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div>

            <Map markers={this.state.markers} />

            <Contact title={this.props.data.wordpressPage.acf.contact.title}
                     image={this.props.data.wordpressPage.acf.contact.image}
                     description={this.props.data.wordpressPage.acf.contact.description} />

            <MultiImageSlider nodes={this.props.data.wordpressPage.acf.become_trainer.impressions}/>

            <Empfehlungen empfehlungen={this.props.data.wordpressPage.acf.cards_page} />
        </Layout>
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                maps {
                    lat
                    lng
                }
                contact {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                maps_marker_title
                maps_marker_description
                maps_marker_link
                slider_page {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                video {
                    file {
                        source_url
                    }
                    thumbnail {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }

                become_trainer {
                    title
                    description
                    courses_title
                    event_filter_categories
                    location {
                        lat
                        long
                    }
                    impresions_title
                    impressions {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                    recommendations_title
                }
                courses_page {
                    title
                    content
                    price
                    location
                    duration
                }
                cards_page {
                    title
                    description
                    link
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;


